import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.featuregroup.subgroup';
import 'leaflet.fullscreen';
import 'leaflet.locatecontrol';
import 'leaflet-groupedlayercontrol';
// import {zoom} from 'leaflet/src/control/Control.Zoom';
import Box from './Box';

/**
 * Creates MapView on website
 * @param {boolean} showLocationCircle Showing accuracy of current location
 * @param {boolean} useBoxBounds only show map inside bounds
 * @param {boolean} loadTiles automatically load tiles when mapview is changing
 * @constructor
 */
const Maps = function(showLocationCircle, useBoxBounds, loadTiles) {
    if (showLocationCircle == null) showLocationCircle = false;
    if (useBoxBounds == null) useBoxBounds = true;
    if (loadTiles == null) loadTiles = false;
    window.leafletMaps = [];
    this.mapsCreated = false;
    this.respectBoxBounds = useBoxBounds;
    this.autoLoadTiles = loadTiles;
    const locationControlOptions = {
        keepCurrentZoomLevel: true,
        drawCircle: showLocationCircle,
        onLocationOutsideMapBounds: function() {
            return undefined;
        },
    };

    this.createMaps = function createMaps(types, mapOptions, markerClusterEnabled, clusterZoomLevel=16, boxArray) {
        if (this.mapsCreated) {
            for (let singleMap of window.leafletMaps) {
                if (singleMap != null && singleMap.combinedMarkerCluster != null) {
                    let markerCluster = singleMap.combinedMarkerCluster;
                    markerCluster.clearLayers();

                    // Initialize counters
                    singleMap.listCount = 0;
                    singleMap.bbsw = null;
                    singleMap.bbno = null;
                    singleMap.boxUids = [];
                    singleMap.respectBoxBounds = this.respectBoxBounds;
                    if (singleMap.locationControl == null && this.respectBoxBounds === false) {
                        singleMap.locationControl = L.control.locate(locationControlOptions).addTo(singleMap);
                    }
                }
            }
            return;
        }

        // Check if all variables are intialized
        let options = mapOptions;
        if (options == null) {
            options = {
                minZoom: 10,
                dragging: true,
                touchZoom: true,
                boxZoom: false,
                scrollWheelZoom: true,
                fullscreenControl: true,
            };
        }
        if (markerClusterEnabled == null) markerClusterEnabled = false;

        for (let type of types) {
            let newMap;
            if (type instanceof Array ) {
                newMap = L.map('map_'.concat(type[0]), options);

                window.leafletMaps[type[0]] = newMap;
                for (let eachType of type) {
                    window.leafletMaps[eachType] = window.leafletMaps[type[0]];
                }
            } else {
                newMap = L.map('map_'.concat(type), options);

                window.leafletMaps[type] = newMap;
            }

            // Add MarkerCluster to the map
            if (markerClusterEnabled) {
                const marClusterGroupOptions = {
                    showCoverageOnHover: true,
                    zoomToBoundsOnClick: true,
                    spiderfyOnMaxZoom: true,
                    removeOutsideVisibleBounds: true,
                    disableClusteringAtZoom: clusterZoomLevel,
                };
                const markerGroup = L.markerClusterGroup(marClusterGroupOptions);
                newMap.addLayer(markerGroup);
                newMap.combinedMarkerCluster = markerGroup;
            }

            // Add array to collect the uids that have been added
            newMap.boxUids = [];

            newMap.respectBoxBounds = this.respectBoxBounds;
            if (newMap.locationControl == null && this.respectBoxBounds === false) {
                newMap.locationControl = L.control.locate(locationControlOptions).addTo(newMap);
            }

            let layer;
            // OLD Mapbox Tile Layer
            /*

            layer = L.tileLayer('https://api.mapbox.com/v4/seewes.0ifib067/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1Ijoic2Vld2VzIiwiYSI6ImNpcTVmbnRxazAwMHFpM25zMG5kYjV1NnIifQ.Jb1mP2v6PeT1XPdZOqYunQ', {
                attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> © <a href=\'http://www.openstreetmap.org/copyright\'>OpenStreetMap</a>',
                maxZoom: 20,
            });

            layer = L.tileLayer('https://api.mapbox.com/styles/v1/seewes/ck7oos5lv0v2e1iplrfjsb5sa/tiles/{z}/{x}/{y}@2x?access_token={accessToken}', {
                attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> © <a href=\'http://www.openstreetmap.org/copyright\'>OpenStreetMap</a>',
                maxZoom: 20,
                tileSize: 512,
                zoomOffset: -1,
                accessToken: 'pk.eyJ1Ijoic2Vld2VzIiwiYSI6ImNpcTVmbnRxazAwMHFpM25zMG5kYjV1NnIifQ.Jb1mP2v6PeT1XPdZOqYunQ',
            });

/*
            layer = L.tileLayer('https://api.mapbox.com/v4/seewes.ck7oos5lv0v2e1iplrfjsb5sa/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1Ijoic2Vld2VzIiwiYSI6ImNpcTVmbnRxazAwMHFpM25zMG5kYjV1NnIifQ.Jb1mP2v6PeT1XPdZOqYunQ', {
                attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> © <a href=\'http://www.openstreetmap.org/copyright\'>OpenStreetMap</a>',
                maxZoom: 20,
            });

            /* const hikebikemapUrl = 'http://{s}.tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png';
             const hikebikemapAttribution = 'Map Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
             layer = new L.TileLayer(hikebikemapUrl, {maxZoom: 20, attribution: hikebikemapAttribution});

             //var hillUrl = 'http://{a,b,c}.tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png';
             const hillUrl = 'http://{s}.tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png';
             const hillAttribution = 'Hillshading: SRTM3 v2 (<a href="http://www2.jpl.nasa.gov/srtm/">NASA</a>)';
             const hill = new L.TileLayer(hillUrl, {maxZoom: 20, attribution: hillAttribution});

             //Mapquest Tile Layer
             /*const layer = L.tileLayer('https://otile4-s.mqcdn.com/tiles/1.0.0/map/{z}/{x}/{y}.png', {
             attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Tiles Courtesy of <a href="http://www.mapquest.com/" target="_blank">MapQuest</a>',
             maxZoom: 18
             });//

            layer = L.tileLayer('http://tiles.lyrk.org/ls/{z}/{x}/{y}?apikey=bd6bfa22ed9747e6a754209432ec5cad', {
                attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Tiles Courtesy Lyrk UG (haftungsbeschränkt), 2014',
                maxZoom: 18,
            });

             */

            layer = L.tileLayer('https://styles.seewes.de/styles/liberty/{z}/{x}/{y}@2x.png', {
                attribution: '<a href="http://www.openmaptiles.org/">© OpenMapTiles </a> <a href="http://openstreetmap.org">© OpenStreetMap contributors</a>',
                maxZoom: 18,
            });


            if (this.autoLoadTiles && boxArray != null) {
                layer.on('tileloadstart',
                    function(e) {
                        boxArray.getTileUrlData(e.coords);
                    });

                newMap.on('click', function(e) {
                    const box = new Box();
                    box.showSlider(false);
                });

                const context = this;
                newMap.on('zoomend', function(e) {
                    if (newMap.getZoom() >= 12) {
                        context.showWarning(false);
                    } else {
                        context.showWarning(true);
                    }
                });
            }

            if (type instanceof Array ) {
                layer.addTo(window.leafletMaps[type[0]]);
            } else {
                layer.addTo(window.leafletMaps[type]);
            }
        }

        this.mapsCreated = true;
    };

    this.startLocationControl = function startLocationControl(start) {
        if (start != null) {
            for (let singleMap of window.leafletMaps) {
                if (singleMap != null && singleMap.locationControl != null) {
                    if (start) {
                        singleMap.locationControl.start();
                    } else {
                        singleMap.locationControl.stopFollowing();
                    }
                }
            }
        }
    };

    this.updateMaps = function updateMaps(location, zoomLevel) {
        if (location != null) {
            for (let singleMap of window.leafletMaps) {
                if (singleMap != null) {
                    if (zoomLevel != null) {
                        singleMap.setView(location, zoomLevel);
                    } else {
                        singleMap.setView(location);
                    }
                    if (singleMap.locationControl != null) {
                        singleMap.locationControl.start();
                    }
                }
            }
        }
    };

    this.updateMapFocus = function updateMapFocus(isFullscreen) {
        for (let singleMap of window.leafletMaps) {
            if (singleMap != null) {
                if (isFullscreen) {
                    let wheight = window.innerHeight;
                    let mapDiv = $(singleMap._container.id);
                    let offset = mapDiv.offset().top;
                    mapDiv.height(wheight - offset);
                }

                singleMap.invalidateSize();
            }
        }
    };

    /**
     * Kreis um den Marker malen.
     * @param {*} box Karte
     * @return {*}
     */
    this.drawMarkerCircle = function(box) {
        this.markerCirlce = L.circle([box.latlon.lat, box.latlon.lon],
            50, {stroke: false, fillColor: '#c44d58'});
        this.markerCirlce.addTo(window.leafletMaps[box.typ]);
        return this.markerCirlce;
    };

    /**
     * Sets map specified as full height to fill the whole screen.
     * @param {*} mapId Id of the map
     */
    this.setFullMapHeight = function(mapId) {
        if (window.leafletMaps[mapId] != null) {
            let wheight = window.innerHeight;
            let mapDiv = $('#map_' + mapId);
            let offset = mapDiv.offset().top;

            mapDiv.height(wheight - offset);
            window.leafletMaps[mapId].invalidateSize();
        } else {
            console.error('Could not set map to full screen size as map id could not be found', mapId);
        }
    };

    /**
     * Show warning if zoomed out too much and tiles are not loading anymore
     * @param show
     */
    this.showWarning = function(show) {
        let zoom = document.getElementById('zoom_warning');
        if (show) {
            zoom.style.visibility = 'visible';
        } else {
            zoom.style.visibility = 'hidden';
        }
    };
};

export default Maps;
