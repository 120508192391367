// GeoPosition
const BoxLatLon = function BoxLatLon(lat, lon) {
    this.lat = lat;
    this.lon = lon;
}

BoxLatLon.prototype.getDistance = function(ref) {
    toRad = function(n) {
        return n * Math.PI / 180;
    };

    let R = 6371000;

    let lat1 = this.lat;
    let lat2 = ref.lat;

    let lon1 = this.lon;
    let lon2 = ref.lon;

    let dLat = toRad(lat2 - lat1);
    let dLong = toRad(lon2 - lon1);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;

    return Math.round(d);
};

BoxLatLon.prototype.bbsw = function(ref) {
    if (ref.lat > this.lat) {
        this.lat = ref.lat;
    }
    if (ref.lon < this.lon) {
        this.lon = ref.lon;
    }
};

BoxLatLon.prototype.bbno = function(ref) {
    if (ref.lat < this.lat) {
        this.lat = ref.lat;
    }
    if (ref.lon > this.lon) {
        this.lon = ref.lon;
    }
};

module.exports = BoxLatLon;
