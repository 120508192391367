const BoxIcons = require('./BoxIcons');
const BoxLatLon = require('./BoxLatLon');
const Box = require('./Box');
const axios = require('axios');

/* eslint-disable max-len */
const BoxArr = function BoxArr(curbox, domnode, types) {
    this.curbox = curbox;
    this.types = [];
    this.area_data = {};
    this.area_marker = {};
    this.markerGroups = {};
    this.activeMarkerGroups = [];
    this.tilesLoading = {};
    this.markerLoading = [];
    this.controlMapping = {};

    if (types != null) {
        this.types.push(...types);
    }

    if (curbox.typ !== undefined) {
        this.types.push(curbox.typ);
    }
    this.typesFlat = [].concat(...this.types);
    this.boxes = [];

    // Only run if their is a list with a domnode to add to
    if (domnode != null) {
        this.domnodes = [];
        this.spinnerDomnodes = [];
        for (let type of this.types) {
            if (type !== undefined) {
                if (type instanceof Array) {
                    this.domnodes[type[0]] = $(domnode + type[0]);
                    this.domnodes[type[0]].html('');
                    this.spinnerDomnodes[type[0]] = $(domnode + 'spinner_' + type[0]);
                    for (let eachType of type) {
                        this.domnodes[eachType] = this.domnodes[type[0]];
                        this.spinnerDomnodes[eachType] = this.spinnerDomnodes[type[0]];
                    }
                } else {
                    this.domnodes[type] = $(domnode + type);
                    this.domnodes[type].html('');
                    this.spinnerDomnodes[type] = $(domnode + 'spinner_' + type);
                }
            }
        }
    }

    this.loadqueue = 0;
    this.loadwidth = 0;
    this.area_loaded = [];

    // Create Category mapping
    /*
    const allBox = new Box();
    const allTypes = allBox.getTypesByCategory('all');
    for (let typeGroup of allTypes) {
        for (let type of typeGroup) {
            const category = allBox.getCategoryByType(type);
            if (this.controlMapping[category] == null) this.controlMapping[category] = {};
            this.controlMapping[category][type] = vending_machines_names[type];
        }
    }*/
};

BoxArr.prototype.resetBoxArray = function(curbox) {
    // Reset initial variables
    if (curbox != null) this.curbox = curbox;
    this.boxes = [];
    this.loadqueue = 0;
    this.loadwidth = 0;
    this.area_loaded = [];

    for (let node of this.domnodes) {
        if (node != null) {
            node.html('');
        }
    }

    for (let spinnerDomnode of this.spinnerDomnodes) {
        if (spinnerDomnode != null) {
            spinnerDomnode.css('display', 'block');
        }
    }
};


BoxArr.prototype.server = {
    zoom: 14,
    urlbase: 'https://api.boxlocator.eu/getBoxArea.php?apikey=ee097e07-44df-11e4-a6dc-50465d9ff9fb&areahex=',
    urlbasegross: 'https://api.boxlocator.eu/getBoxAreaGross.php?apikey=ee097e07-44df-11e4-a6dc-50465d9ff9fb&areagrosshex=',
    urlbasedetail: 'https://api.boxlocator.eu/getBox.php?apikey=855b4830-1d62-11e4-8c21-0800200c9a66&uid=',
    urlbasedetailsite: '/detail/box/uid/',
    urlbasearound: 'https://boxlocator.eu/around/box/position/', // 50.7415352/25.3544522/%5B20%2C21%2C27%5D/20'
    // urlbasearound: '../../../../around/box/position/'
};

BoxArr.prototype.add = function(box) {
    // Nicht der aktuelle
    // if (box.uid == this.curbox.uid){return;};
    // Nur gleiche Typen
    if (this.typesFlat.includes(parseInt(box.typ))) {
        // Entfernungen speichern
        box.distance = box.latlon.getDistance(this.curbox.latlon);
        this.boxes.push(box);
    }
};

BoxArr.prototype.sort = function() {
    this.boxes.sort(function(a, b) {
        return a.distance - b.distance;
    });
};

BoxArr.prototype.load = function(lat, lon) {
    if (lat == null) lat = this.curbox.latlon.lat;
    if (lon == null) lon = this.curbox.latlon.lon;
    for (let type of this.types) {
        if (type !== undefined) {
            if (type instanceof Array) {
                this.loadAreas(lat, lon, type);
            } else {
                this.loadAreas(lat, lon, [type]);
            }
        }
    }
};

BoxArr.prototype.loadAreas = function(lat, lon, types) {
    let url = this.server.urlbasearound + lat + '/' + lon + '/' + JSON.stringify(types) + '/' + '10';
    $.ajax({
        url: url,
        context: this,
    }).done(function(data) {
        // json = $.parseJSON(data);
        let thisc = this;

        if (data != null) {
            $.each(data.boxes,
                function(k, v) {
                    const box = new Box(v.lat, v.lon, v.typ, v.uid, v.name);
                    thisc.add(box);

                    return true;
                }
            );
        }

        // Hide spinner for the requested boxes
        if (types instanceof Array) {
            for (let type of types) {
                this.spinnerDomnodes[parseInt(type)].css('display', 'none');

                // Show error message when no results found
                if (data == null || data.boxes == null || data.boxes.length <= 0) {
                    let noResults = 'No Results';
                    let lang = 'en';
                    if (boxlocatorHtmlCurrCity != null && boxlocatorHtmlCurrCity.language != null) {
                        lang = boxlocatorHtmlCurrCity.language;
                    }

                    if (lang === 'it') {
                        noResults = 'Nessun Risultato';
                    } else if (lang === 'ja') {
                        noResults = '結果はありません';
                    } else if (lang === 'de') {
                        noResults = 'Keine Ergebnisse';
                    }

                    this.domnodes[parseInt(type)].html(
                        '<tr ' + ' style="cursor:pointer;">'+
                        '<th scope="row"></th>'+
                        '<td><p>' + noResults + '</p></td>'+
                        '<td style="text-align:right;"></td>'+
                        '</tr>'
                    );
                }
            }
        }

        // Versuche anzuzeigen
        this.loadwidth = 6;
        this.onload();
    });
};

/**
 * Calculate tiles which should be loaded
 * @param url Location of the tile
 */
BoxArr.prototype.getTileUrlData = function(url) {
    // console.log(url);
    // Only used with new hd map tiles
    // let zoom = url.z - 1;
    let zoom = url.z;
    let x = url.x;
    let y = url.y;
    if (zoom < 12) return;

    let serverZoom = 14;
    let coordinates = [];

    if (zoom >= serverZoom) {
        x = Math.floor(x / (Math.pow(2, (zoom - serverZoom))));
        y = Math.floor(y / (Math.pow(2, (zoom - serverZoom))));
        coordinates = [[x, y]];
    } else {
        const divider = (Math.pow(2, (serverZoom - zoom)));
        const xMax = x * divider;
        const yMax = y * divider;
        for (let xi = divider; xi >= 0; xi--) {
            for (let yi = divider; yi >= 0; yi--) {
                const x = xMax + xi;
                const y = yMax + yi;
                coordinates.push([x, y]);
            }
        }
    }

    // console.log(coordinates);
    // console.log(x, y);
    for (let coordinate of coordinates) {
        const area = this.getTileAreaHex(coordinate[0], coordinate[1]);
        this.getAreaAjax(area);
    }
};

BoxArr.prototype.getTileAreaHex = function(x, y) {
    let ystr = '00000000' + y.toString(16);
    return x.toString(16) + ystr.substring(ystr.length - 8);
};

BoxArr.prototype.getAreaAjax = function(area) {
    if (this.area_loaded[area] == null) {
        // check if tile is already loading for more than 10 seconds, otherwise skip loading of tile
        if (this.tilesLoading[area] == null || (Date.now() - this.tilesLoading[area])>10000) {
            this.tilesLoading[area] = Date.now();
            let url = this.server.urlbase + area;
            console.debug(url);
            let context = this;

            axios.get(url)
                .then(function(response) {
                    context.tilesLoading[area] = null;
                    context.addAreaData(area, response.data);
                    context.area_loaded[area] = true;
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }
};

/**
 * Validates if the marker of this type should be shown.
 * @param type Type to be checked
 */
BoxArr.prototype.typisAllowed = function(type) {
    if (this.typesFlat == null) {
        return true;
    } else {
        return this.typesFlat.includes(type);
    }
};

/**
 * Reads json and copies information to object. Afterwards adds icon to map.
 * @param area Area for which the json is defined
 * @param json Json of the area
 */
BoxArr.prototype.addAreaData = function(area, json) {
    // JSON auf validität prüfen
    // TODO

    // Wenn area nicht vorhanden Objekt initialisieren
    if (!(area in this.area_data)) {
        this.area_data[area] = {};
    }
    // Eigenschaften in Array kopieren
    for (const uid in json.list) {
        if (uid != null) {
            const values = json.list[uid];
            if (this.typisAllowed(parseInt(values.typ))) {
                this.area_data[area][uid] = {typ: values.typ, lat: values.lat, lon: values.lon};
            }
        }
    }
    this.addMarker(area);
};

BoxArr.prototype.markerClick = async function(e) {
    console.log(this.options.uid);

    const urlbasedetail = 'https://api.boxlocator.eu/getBox.php?apikey=855b4830-1d62-11e4-8c21-0800200c9a66&uid=';
    const url = urlbasedetail + this.options.uid;
    let alternativeDisplayName;

    const response = await axios.get(url);
    if (response.data.displayname == null) {
        try {
            alternativeDisplayName = (await axios.get(`https://boxlocator.eu/geocode/address/uid/${document.documentElement.lang}/${this.options.uid.split('-')[0]}`)).data[0].display_name;
        } catch (error) {}
        if (alternativeDisplayName != null) {
            response.data.displayname = alternativeDisplayName;
        }
    }
    const box = new Box(response.data.lat, response.data.lon, response.data.typ, response.data.uid, response.data.name, response.data.displayname);
    box.showSlider(true);
};

/**
 * Adds Marker for given area to map.
 * @param area Area for which marker should be added
 */
BoxArr.prototype.addMarker = function(area) {
    let boxTypeSelected = false;
    if (this.types != null) {
        boxTypeSelected = true;
    }

    if (this.area_marker[area] == null) this.area_marker[area] = {};
    for (let uid in this.area_data[area]) {
        if (uid != null) {
            let existingMarker = true;
            if (this.area_marker[area][uid] == null) {
                this.area_marker[area][uid] = L.marker([this.area_data[area][uid].lat, this.area_data[area][uid].lon], {area: area, uid: uid, title: this.area_data[area][uid].name, icon: BoxIcons.get(this.area_data[area][uid].typ).icon});
                this.area_marker[area][uid].on('click', this.markerClick);
                existingMarker = false;
            }

            // Create new layer group if new typ, else add layer to existing layer group
            let typid = this.area_data[area][uid].typ;
            const map = window.leafletMaps[parseInt(typid)];
            let typName = vending_machines_names[typid];

            if (this.markerGroups[typName] == null) {
                // Create a new marker cluster group
                this.markerGroups[typName] = L.featureGroup.subGroup(map.combinedMarkerCluster);
                // this.markerGroups[typName].addLayer(this.area_marker[area][uid]);
                if (existingMarker === false) {
                    this.markerGroups[typName].addLayer(this.area_marker[area][uid]);
                }
                this.markerGroups[typName].addTo(map);

                // Adds markerGroup to Map
                // this.markerGroups[typName] = L.layerGroup(this.area_marker[area][uid]).addTo(map);

                // Adds actualized Controller to map
                if (this.controller != null) this.controller.remove();

                // Create a layer to select all layers
                const options = {
                    groupCheckboxes: true,
                };

                const groupedOverlays = {
                    [translations.all]: this.markerGroups,
                };

                // Initialize layer and add marker groups
                this.controller = L.control.groupedLayers({}, groupedOverlays, options).addTo(map);
                this.activeMarkerGroups.push(typName);
            } else {
                if (existingMarker === false) this.markerGroups[typName].addLayer(this.area_marker[area][uid]);
            }

            // map.addLayer(this.area_marker[area][uid]);
        }
    }
};

BoxArr.prototype.onload = function() {
    this.loadqueue = 0;

    if ((this.boxes.length > 5) && (this.loadwidth === 1) && false) {
        this.print();
        return;
    } else if (((this.boxes.length > 10) && (this.loadwidth >= 2)) || (this.loadwidth > 5)) {
        this.print();
        return;
    } else {
        this.load();
    }
};

BoxArr.prototype.print = function() {
    if (this.loadqueue <= 0) {
        this.sort();

        for (let v of this.boxes) {
            if (v != null ) {
                const map = window.leafletMaps[parseInt(v.typ)];

                // check if box has been added already
                if (map.boxUids.includes(v.uid) === false) {
                    let markerCluster = map.combinedMarkerCluster;
                    if (markerCluster == null) markerCluster = map;

                    let listCount = map.listCount;

                    if (this.domnodes != null) {
                        if (listCount == null || listCount < 10) {
                            let count = 0;
                            if (listCount != null) count = listCount;
                            const url = this.server.urlbasedetailsite + v.uid;
                            const onclickText = 'onclick="location.href=\'' + url + '\'"';
                            this.domnodes[parseInt(v.typ)].append(
                                '<tr '+ onclickText + ' style="cursor:pointer;">'+
                                '<th scope="row"><a href="' + url + '">' + (count+1) + '</a></th>'+
                                '<td><a href="' + url + '">' + v.name + '</a></td>'+
                                '<td style="text-align:right;"><a href="' + url + '"><span class="badge badge-pill green">' + v.distance + ' m</span></a></td>'+
                                '</tr>'
                            );
                        }
                    }

                    // Add marker to the map and notice it's uid
                    const marker = v.getMarker();
                    marker.addTo(markerCluster);
                    map.boxUids.push(v.uid);

                    if (map.respectBoxBounds) {
                        // Determine bounds of map
                        if (map.bbsw == null || map.bbno == null) {
                            map.bbsw = new BoxLatLon(this.curbox.latlon.lat, this.curbox.latlon.lon);
                            map.bbno = new BoxLatLon(this.curbox.latlon.lat, this.curbox.latlon.lon);
                        }
                        map.bbsw.bbsw(v.latlon);
                        map.bbno.bbno(v.latlon);
                    }

                    if (listCount === undefined) {
                        map.listCount = 1;
                    } else {
                        map.listCount++;
                    }
                    // return true;// (i <10);
                }
            }
        }

        // Fit the view to the added boxes
        for (let singleMap of window.leafletMaps ) {
            if (singleMap !== undefined) {
                singleMap.invalidateSize();

                if (singleMap.bbsw != null && singleMap.bbno != null) {
                    let bb = L.latLngBounds(L.latLng(singleMap.bbsw.lat, singleMap.bbsw.lon), L.latLng(singleMap.bbno.lat, singleMap.bbno.lon));
                    bb = bb.pad(0.2);
                    singleMap.setMaxBounds(bb);
                }
            }
        }
    }
};

module.exports = BoxArr;
