const BoxLatLon = require('./BoxLatLon');
const BoxIcons = require('./BoxIcons');

const Box = function Box(lat, lon, typ, uid, name, displayname) {
    this.uid = uid;
    this.typ = typ;
    this.name = name;
    this.displayname = displayname;

    this.latlon = new BoxLatLon(lat, lon);
};


Box.prototype.server = {
    zoom: 14,
    urlbase: 'https://api.boxlocator.eu/getBoxArea.php?apikey=ee097e07-44df-11e4-a6dc-50465d9ff9fb&areahex=',
    urlbasegross: 'https://api.boxlocator.eu/getBoxAreaGross.php?apikey=ee097e07-44df-11e4-a6dc-50465d9ff9fb&areagrosshex=',
    urlbasedetail: 'https://api.boxlocator.eu/getBox.php?apikey=855b4830-1d62-11e4-8c21-0800200c9a66&uid=',
    urlbasedetailsite: '/detail/box/uid/',
    urlbasearound: 'https://boxlocator.eu/around/box/position/', // 50.7415352/25.3544522/%5B20%2C21%2C27%5D/20'
    // urlbasearound: '../../../../around/box/position/'
};

Box.prototype.getNameByTyp = function(typ) {
    const typNum = [];
    typNum[0] = 'BICYCLE_TUBE';
    typNum[1] = 'CIGARETTES';
    typNum[2] = 'CONDOMS';
    typNum[3] = 'GASSTATION_E';
    typNum[4] = 'GASSTATION';
    typNum[5] = 'EXCREMENT_BAGS';
    typNum[6] = 'FOOD';
    typNum[7] = 'MONEY';
    typNum[8] = 'NEWS_PAPER';
    typNum[9] = 'PARCEL_IN_OUT';
    typNum[10] = 'PARKING';
    typNum[11] = 'PHOTO_PRINT';
    typNum[12] = 'TRANSPORT_PUBLIC';
    typNum[13] = 'STAMPS';
    typNum[14] = 'TELEPHONE_VOUCHER';
    typNum[15] = 'TELEPHONE';
    typNum[16] = 'TICKETS';
    typNum[17] = 'TOLL';
    typNum[18] = 'VOUCHERS';
    typNum[19] = 'ATM';
    typNum[20] = 'BANK';
    typNum[21] = 'POST_OFFICE';
    typNum[22] = 'POST_BOX';
    typNum[23] = 'PHOTO_BOOTH';
    typNum[24] = 'BICYCLE_RENTAL';
    typNum[25] = 'DRINKS';
    typNum[26] = 'FOOD_ANIMAL';
    typNum[27] = 'RECYCLING';

    return typNum[typ];
};

Box.prototype.getTypesByCategory = function(category) {
    let types;
    if (category !== undefined && category !== null) {
        if (category === 'commodities') {
            types = [1, 6, 25, 2, 8];
        } else if (category === 'money') {
            types = [19, 20];
        } else if (category === 'post') {
            types = [9, 13, 21, 22];
        } else if (category === 'phone') {
            types = [14, 15];
        } else if (category === 'recycling') {
            types = [5, 27];
        } else if (category === 'public_transport') {
            types = [0, 10, 3, 4, 12, 24, 17];
        } else if (category === 'photo') {
            types = [11, 23];
        } else if (category === 'standard') {
            types = [-1, 18, 26, 16];
        } else if (category === 'all') {
            types = [];
            types.push([1, 6, 25, 2, 8]);
            types.push([19, 20]);
            types.push([9, 13, 21, 22]);
            types.push([14, 15]);
            types.push([5, 27]);
            types.push([0, 10, 3, 4, 12, 24, 17]);
            types.push([11, 23]);
            types.push([-1, 18, 26, 16]);
        }
    }
    return types;
};


Box.prototype.getCategoryByType = function(type) {
    if (type !== undefined && type !== null) {
        if ([1, 6, 25, 2, 8].includes(type)) {
            return 'commodities';
        } else if ([19, 20].includes(type)) {
            return 'money';
        } else if ([9, 13, 21, 22].includes(type)) {
            return 'post';
        } else if ([14, 15].includes(type)) {
            return 'phone';
        } else if ([5, 27].includes(type)) {
            return 'recycling';
        } else if ([0, 10, 3, 4, 12, 24, 17].includes(type)) {
            return 'public_transport';
        } else if ([11, 23].includes(type)) {
            return 'photo';
        } else if ([-1, 18, 26, 16].includes(type)) {
            return 'standard';
        }
    }
    return 'standard';
};


Box.prototype.getTileAreaHex = function(x, y) {
    let ystr = '00000000' + y.toString(16);
    return x.toString(16) + ystr.substring(ystr.length - 8);
};

Box.prototype.lon2tile = function(lon) {
    let zoom = Box.prototype.server.zoom;
    return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom)));
};
Box.prototype.lat2tile = function(lat) {
    let zoom = Box.prototype.server.zoom;
    return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 /
            Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)));
};

/**
 * Kreis um den Marker malen.
 * @param {*} map Karte
 * @return {*}
 */
Box.prototype.drawMarkerCircle = function(map) {
    this.markerCirlce = L.circle([this.latlon.lat, this.latlon.lon],
        50, {stroke: false, fillColor: '#c44d58'});
    this.markerCirlce.addTo(map);
    return this.markerCirlce;
};

Box.prototype.removeMarkerCircle = function(map) {
    if (this.markerCircle !== null) {
        map.removeLayer(this.markerCirlce);
    }
};

Box.prototype.getMarker = function() {
    return new L.marker([this.latlon.lat, this.latlon.lon],
        {
            uid: this.uid,
            title: this.name,
            icon: BoxIcons.get(this.typ).icon,
        }
    ).on('click', function(e) {
        window.location.href = Box.prototype.server.urlbasedetailsite +
            this.options.uid;
    });
};

Box.prototype.showSlider = function(show) {
    let slidingElement = document.getElementById('map_overlay_slider');
    let slidingElementContent = document.getElementById('map_overlay_slider_content');
    let slidingElementIcon = document.getElementById('map_overlay_slider_icon');
    let slidingElementHeadline = document.getElementById('map_overlay_slider_headline');
    let slidingElementDescription = document.getElementById('map_overlay_slider_description');

    if (show && this.uid != null && this.name != null && this.typ != null) {
        slidingElementContent.style.backgroundColor = BoxIcons.get(this.typ).color;
        slidingElementIcon.src = BoxIcons.get(this.typ).icon_round;
        slidingElementHeadline.innerText = this.name;
        slidingElementDescription.innerText = this.displayname;

        const uid = this.uid;
        slidingElementContent.onclick = function() {
            const url = '/detail/box/uid/' + uid;
            console.log(url)
            window.location = url;
        };

        if (typeof Piwik != 'undefined') {
            Piwik.getAsyncTracker().trackEvent(this.typ, 'Auswahl', this.name, this.uid);
        }

        if (slidingElement.style.display == 'none' || slidingElement.style.display == '') {
            $('.map-overlay').slideToggle('fast', 'swing');
        }

        $('#map_overlay_slider_description').stop(true, true);
        $('#map_overlay_slider_description').removeAttr('style');
        $('#map_overlay_slider_headline').stop(true, true);
        $('#map_overlay_slider_headline').removeAttr('style');
        if (typeof this.sliderLeft === 'function') {
            this.sliderLeft();
        }
    } else {
        if (slidingElement.style.display != 'none' && slidingElement.style.display != '') {
            $('.map-overlay').slideToggle('fast', 'swing');
        }

        $('#map_overlay_slider_description').stop(true, true);
        $('#map_overlay_slider_description').removeAttr('style');
        $('#map_overlay_slider_headline').stop(true, true);
        $('#map_overlay_slider_headline').removeAttr('style');
    }

    return false;
};


Box.prototype.sliderLeft = function() {
    let slidertemp = $('#map_overlay_slider_description');
    let slidertempH = $('#map_overlay_slider_headline');

    let sliderwidth = $('#map_overlay_slider_description_div')[0].offsetWidth + 10;
    let sliderwidthH = $('#map_overlay_slider_headline_div')[0].offsetWidth + 10;
    let position = slidertemp.position();
    let positionH = slidertempH.position();
    let offset = slidertemp[0].scrollWidth;
    let offsetH = slidertempH[0].scrollWidth;
    let newposition = position.left - (offset - sliderwidth) - 10;
    let newpositionH = positionH.left - (offsetH - sliderwidthH) - 10;

    console.log('Position: ' + positionH.left);
    console.log('offset: ' + offsetH);
    console.log('newposition: ' + newpositionH);

    slidertemp.animate({
        'left': newposition,
    }, {
        duration: 4000,
        complete: function() {
        },
    });
    slidertempH.animate({
        'left': newpositionH,
    }, {
        duration: 4000,
        complete: function() {
        },
    });

    slidertemp.animate({
        'left': 0,
    }, {
        duration: 5000,
        complete: function() {},
    });
    slidertempH.animate({
        'left': 0,
    }, {
        duration: 5000,
        complete: function() {
            if (typeof this.sliderLeft === 'function') {
                this.sliderLeft();
            }
        },
    });
};

module.exports = Box;
