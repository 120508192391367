const L = require('leaflet');

let blicons = {};

blicons.newcatvec = function(colorHex, path) {
    let width = 18;
    let ratio = 45 / 23;
    let icon = new L.divIcon({
        iconUrl: '../blicons/' + path + '.svg',
        html: '<img style="border-width: 0;" alt="" width="' + width + '" src="https://boxlocator.eu/blicons/' + path + '.svg">',
        iconAnchor: L.point(width / 2, width * ratio),
        popupAnchor: L.point(0, -width * ratio - 1),
        className: 'boxlocator-div-icon',
    });
    let color = colorHex;
    let iconRound = 'https://boxlocator.eu/blicons/' + path + '_round.png';

    return {'color': color, 'icon': icon, 'icon_round': iconRound};
};

// Standard
blicons.standard = blicons.newcatvec('#797e86', 'icon_standard');

// BICYCLE_TUBE
blicons[0] = blicons.newcatvec('#a1bee6', 'icon_bicycle_tube');
// Cigarettes
blicons[1] = blicons.newcatvec('#f27581', 'icon_cig');
// CONDOMS
blicons[2] = blicons.newcatvec('#f27581', 'icon_con');
// GASSTATION_E
blicons[3] = blicons.newcatvec('#a1bee6', 'icon_gas_e');
// GASSTATION
blicons[4] = blicons.newcatvec('#a1bee6', 'icon_gas');
// EXCREMENT_BAGS
blicons[5] = blicons.newcatvec('#d4c098', 'icon_excbags');
// FOOD
blicons[6] = blicons.newcatvec('#f27581', 'icon_food');
// MONEY
blicons[7] = blicons.newcatvec('#a4cfa2', 'icon_money');
// NEWS_PAPER
blicons[8] = blicons.newcatvec('#f27581', 'icon_newspaper');
// PARCEL_IN_OUT
blicons[9] = blicons.newcatvec('#edd100', 'icon_parcelinout');
// PARKING
blicons[10] = blicons.newcatvec('#a1bee6', 'icon_parken');
// PHOTO_PRINT
blicons[11] = blicons.newcatvec('#d0a7c5', 'icon_photoprint');
// TRANSPORT_PUBLIC
blicons[12] = blicons.newcatvec('#a1bee6', 'icon_public_transport');
// STAMPS
blicons[13] = blicons.newcatvec('#edd100', 'icon_stamp');
// TELEPHONE_VOUCHER
blicons[14] = blicons.newcatvec('#e79963', 'icon_sim');
// TELEPHONE
blicons[15] = blicons.newcatvec('#e79963', 'icon_phone');
// TICKETS
blicons[16] = blicons.newcatvec('#797e86', 'icon_ticket');
// TOLL
blicons[17] = blicons.newcatvec('#a1bee6', 'icon_toll');
// VOUCHERS
blicons[18] = blicons.newcatvec('#797e86', 'icon_voucher');
// ATM
blicons[19] = blicons.newcatvec('#a4cfa2', 'icon_money');
// BANK
blicons[20] = blicons.newcatvec('#a4cfa2', 'icon_bank');
// POST_OFFICE
blicons[21] = blicons.newcatvec('#edd100', 'icon_post_office');
// POST_BOX
blicons[22] = blicons.newcatvec('#edd100', 'icon_post');
// PHOTO_BOOTH
blicons[23] = blicons.newcatvec('#d0a7c5', 'icon_photobooth');
// BICYCLE_RENTAL
blicons[24] = blicons.newcatvec('#a1bee6', 'icon_bicycle');
// DRINKS
blicons[25] = blicons.newcatvec('#f27581', 'icon_drinks');
// FOOD_ANIMAL
blicons[26] = blicons.newcatvec('#797e86', 'icon_animalfood');
// RECYCLING
blicons[27] = blicons.newcatvec('#d4c098', 'icon_recycling');

blicons.get = function(cat) {
    if (cat in this) {
        return this[cat];
    } else {
        return this.standard;
    }
};

// export default blicons;
module.exports = blicons;
